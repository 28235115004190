.coupon-holder {
    display: flex;
    flex-wrap: wrap;
    .box {
        display: flex;
        flex-direction: column;
        gap: 6px;
        border: 1px solid #c7c7c7;
        width: 360px;
        color: black;
        padding: 10px;
        margin: 8px;
        border-radius: 8px;
        span {
            font-size: 14px;
            font-weight: 100;
        }
        .ident {
            color: rgb(48, 48, 48);
            font-size: 14px;

            strong {
                font-weight: 400;
            }
        }
        button {
            // background-color: transparent;
            // border: none;
            margin: 0 0 0 8px;
            height: 30px;
            img {
                height: 16px;
            }
        }
    }
}